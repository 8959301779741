@import './vars.scss';

.lobee {
  font-family: 'Comfortaa', cursive;
}

.button,
button {
  font-family: 'Comfortaa', cursive;
  line-height: 50px;
  font-size: 16px;
  background-color: $dark;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 0 30px;
  cursor: pointer;
  display: inline-block;
  text-align: center;

  @media (max-width: $tablet) {
    line-height: 40px;
    padding: 0 10px;
  }

  &.--secondary {
    background-color: #fff;
    color: $dark;
  }

  &:hover {
    background-color: $light;
    color: #fff;
  }
}
