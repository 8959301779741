@import './reseter.scss';
@import './vars.scss';

@import url('https://fonts.googleapis.com/css?family=Comfortaa:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');

body * {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

a,
a:active,
a:hover,
a:visited {
  text-decoration: none;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  padding-left: $margin;
  padding-right: $margin;

  @media (max-width: $tablet) {
    padding-left: $margin / 2;
    padding-right: $margin / 2;
  }
}

.PrivacyPolicy,
.TermsOfUse {
  h1 {
    margin-bottom: 32px;
  }

  h2,
  h3 {
    margin: 24px 0 16px 0;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    margin: 16px 0 16px;
  }

  p {
    margin-bottom: 8px;

    strong {
      font-weight: 500;
    }
  }

  a {
    word-break: break-all;
  }

  ul {
    padding-left: 56px;
    margin-bottom: 8px;

    li {
      list-style-type: circle;
    }
  }
}

@import './ui.scss';
