.Unsubscribe {
  &__text {
    margin: 30px 0;
    text-align: center;
  }

  button {
    display: block;
    margin: 0 auto;
  }
}
