$dark: #e4a232;
$light: #f6c966;
$brown: #35220e;
$brownLight: #875b40;
$error: #dc3545;
$errorBackground: #f6b2b8;
$grey: #eee;

$margin: 30px;

$tablet: 768px;
$mobile: 425px;
